<template>
 <LogEventsTable />
</template>

<script>
import LogEventsTable from './LogEventsTable';

export default {
  name: 'LogEvents',
  components: { LogEventsTable }
};
</script>

<style scoped>

</style>