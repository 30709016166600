<template>
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="logEvents"
      :isCreateButtonVisible="false"
      :isCogFieldsButtonVisible="true"
      :isFilterButtonVisible="true"
  />
</template>
<script>
import { logEvents } from '@/config/descriptions/logEvents';
import { logEventsTexts } from '@/config/pageTexts/logEvents';

export default {
  name: 'LogEventsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'logEvents/logEvents',
          fields: 'logEvents/fields',
          sortableFields: 'logEvents/sortableFields',
          pages: 'logEvents/pages',
          loading: 'logEvents/loading',
          resultCount: 'logEvents/resultCount',
          totalRecords: 'logEvents/totalRecords',
          originalFields: 'logEvents/originalFields',
          searchableAllFields: 'logEvents/searchableAllFields',
          success: 'logEvents/success',
          message: 'logEvents/message',
        },
        actions: {
          fields: 'logEvents/handleFields',
          reset: 'logEvents/handleClearFilter',
          query: 'logEvents/handleGetLogEventsByQuery',
        },
      },
      settings: {
        describe: { ...logEvents },
        page: { ...logEventsTexts },
      },
      route: {
        preview: 'PreviewLogEvent',
      },
    };
  }
};
</script>